<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <div class="d-flex justify-content-center">
          <b-img
            fluid

            :src="require('@/assets/images/production/HdayLogo.png')"
            style="width:100%;max-width: 200px;"
          />

        </div>

        <validation-observer
          ref="loginForm"
        >
          <b-form
            class="auth-login-form "
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="User"
              label="User"
            >
              <validation-provider
                #default="{ errors }"
                name="User"
                rules="required"
              >
                <b-form-input
                  id="User"
                  v-model="user"
                  name="login-user"
                  :state="errors.length > 0 ? false:null"
                  placeholder="User"
                  autofocus
                />
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >กรุณาใส่ชิ่อผู้ใช้งาน</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="Password"
              label="Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >กรุณาใส่รหัสผ่าน</small>
              </validation-provider>
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              block
              class="mb-2"
              @click="loginJWT"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BInputGroup, BInputGroupAppend, BImg
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
// import ability from '@/libs/acl/ability'

export default {
  name: 'Login',
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    // BCardTitle,
    // BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      user: '',
      password: '',
      required

    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    loginJWT () {
      // if (!this.checkLogin()) return
      // // Loading
      // this.$vs.loading()

      const payload = {
        username: this.user,
        password: this.password
      }
      this.$store
        .dispatch('auth/loginJWT', payload)
        .then(result => {
          if (result.data.success) {
            const userData = result.data.item
            useJwt.setToken(result.data.accessToken)
            useJwt.setRefreshToken(result.data.refreshToken)
            userData.ability = [{
              action: 'manage',
              subject: 'all'
            }]
            localStorage.setItem('userData', JSON.stringify(userData))
            console.log(result.data)

            this.$ability.update(userData.ability)

            this.$router.replace('/')
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `ยินดีตอนรับ ${userData.fullName || userData.username}`,
                    icon: 'AlertCircleIcon',
                    variant: 'success',
                    text: `คุณล็อกอินในตำแหน่งของ ${userData.role}`
                  }
                })
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'ล็อกอินไม่สำเร็จ',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: 'ชื่อผู้ใช้หรือรหัสผ่านผิดพลาด กรุณาลองใหม่อีกครั้ง'
              }
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ล็อกอินไม่สำเร็จ',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.message
            }
          })
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
